import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardMedia,
  Divider,
  FormControlLabel,
  FormGroup,
  Link,
  Switch,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  AccountBalance,
  AccountCircle,
  Audiotrack,
  Label,
  Place,
  Videocam,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import ImgLoader from 'generic/components/ui/ImgLoader';
import ExpandableTagList from 'generic/components/ui/ExpandableTagList';
import ImageNotFound from 'generic/components/ui/ImageNotFound';
import QES_CONSTANTS from 'generic/core/qes/constants';
import { overrideRessource } from 'generic/utils/utils';
import { documentPropType } from 'generic/core/qes/proptypes';

const { default: useDocumentFields } = overrideRessource('core/hooks/useDocumentFields', true);
const { CLICK_ON_DOCS_TITLE_BEHAVIOR } = QES_CONSTANTS;

const useStyles = makeStyles((theme) => ({
  itemImage: {
    objectFit: 'cover',
    display: 'block',
    borderRadius: '4px',
    boxSizing: 'border-box',
    border: '1px solid',
    borderColor: theme.palette.divider,
  },
}));

const Document = ({
  disableTagsRefine,
  displayTitle,
  document,
  handleGetDocumentComplete,
  handleToggleAnnotatedTextDisplay,
  isAnnotatedTextDisplay,
  isCompleteDisplay,
  shrinkDocumentDisplay,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const smallerThanMedium = useMediaQuery(theme.breakpoints.down('md'));
  const {
    title, linkout, pdf, date, status, thumb, snippet, text, source, htmlWithAnnotations, urlVideo, urlAudio, sentences,
  } = useDocumentFields(document);

  const { qesdocument } = document;

  let titleLinkProps;
  if (CLICK_ON_DOCS_TITLE_BEHAVIOR === 'newTab') {
    titleLinkProps = {
      component: Link,
      href: linkout,
      rel: 'noopener',
      target: '_blank',
      underline: 'none',
    };
  } else if (CLICK_ON_DOCS_TITLE_BEHAVIOR === 'complete') {
    titleLinkProps = {
      component: 'span',
      onClick: handleGetDocumentComplete,
    };
  }
  return (
    <Box display="block" width="100%" sx={{ wordBreak: 'break-word' }}>
      {displayTitle && (
        <Typography
          variant="h6"
          component="span"
          fontSize="1.2rem"
          margin="6px 0"
          display="block"
        >
          <Box
            {...titleLinkProps}
            color="text.primary"
            sx={{ cursor: 'pointer' }}
          >
            {title}
          </Box>
        </Typography>
      )}
      <Box display="flex" alignItems="flex-start">
        <Box flexGrow="1">
          {!smallerThanMedium && thumb && (
            <Box mr={1} mb="2px" sx={{ float: 'left' }}>
              <ImgLoader
                src={thumb}
                fallbackComponent={(
                  <ImageNotFound
                    height={60}
                    width={100}
                  />
                )}
                className={classes.itemImage}
                skeletonClassName={classes.itemImage}
                height={60}
                width={100}
                loading="lazy"
              />
            </Box>
          )}
          <Box lineHeight="1.3rem">
            <Box
              display="flex"
              flexWrap="wrap"
              mb={0.5}
            >
              {date && (
                <Typography
                  component="span"
                  variant="body2"
                  color="text.neutral"
                >
                  {date}
                </Typography>
              )}
              {!shrinkDocumentDisplay && status > 0 && (
                <Fragment>
                  {date && (<Box mx={1} py="3px"><Divider orientation="vertical" /></Box>)}
                  <Typography
                    component="span"
                    variant="body2"
                    color="text.neutral"
                  >
                    {t(`ged.document.status.${status}`)}
                  </Typography>
                </Fragment>
              )}
              {pdf && (
                <Fragment>
                  {(date || status > 0) && (<Box mx={1} py="3px"><Divider orientation="vertical" /></Box>)}
                  <Link
                    href={pdf}
                    rel="noopener"
                    target="_blank"
                    underline="none"
                  >
                    <Typography
                      component="span"
                      variant="body2"
                    >
                      PDF
                    </Typography>
                  </Link>
                </Fragment>
              )}
              {source && (
                <Fragment>
                  {(date || status > 0 || pdf) && (<Box mx={1} py="3px"><Divider orientation="vertical" /></Box>)}
                  <Typography
                    component="span"
                    variant="body2"
                    color="text.neutral"
                  >
                    {source}
                  </Typography>
                </Fragment>
              )}
              {!shrinkDocumentDisplay && CLICK_ON_DOCS_TITLE_BEHAVIOR === 'complete' && linkout && (
                <Fragment>
                  {(date || status > 0 || pdf || source)
                    && (<Box mx={1} py="3px"><Divider orientation="vertical" /></Box>)}
                  <Link
                    href={linkout}
                    rel="noopener"
                    target="_blank"
                    underline="none"
                  >
                    <Typography
                      component="span"
                      variant="body2"
                    >
                      {t('actions.open_in_new_tab')}
                    </Typography>
                  </Link>
                </Fragment>
              )}
              {!isCompleteDisplay && (urlAudio || urlVideo) && (
                <Fragment>
                  {(date || status > 0 || pdf || source || linkout)
                    && (<Box mx={1} py="3px"><Divider orientation="vertical" /></Box>)}
                  <Typography
                    component="span"
                    variant="body2"
                    className="item"
                  >
                    { urlVideo && <Videocam fontSize="small" />}
                    { urlAudio && <Audiotrack fontSize="small" />}
                  </Typography>
                </Fragment>
              )}
            </Box>
          </Box>
          {sentences && (
            sentences.map((sentence) => (
              <Box p={1} mt={0.5} mb={1} borderLeft="5px solid #d3d3d3" whiteSpace="pre-wrap" lineHeight="22px">
                {sentence}
              </Box>
            ))
          )}
          {!isCompleteDisplay && (
            <Box component="span" lineHeight="1.3rem">
              {snippet}
            </Box>
          )}
          {!shrinkDocumentDisplay && (
            <Box mt={0.5}>
              <ExpandableTagList
                disableRefine={disableTagsRefine}
                qesdocument={qesdocument}
                tags={[
                  { fieldName: 'QES_Company', icon: AccountBalance },
                  { fieldName: 'QES_Person', icon: AccountCircle },
                  { fieldName: 'QES_Location', icon: Place },
                  { fieldName: 'QES_ConceptCategorized', icon: Label },
                ]}
              />
            </Box>
          )}
          {isCompleteDisplay && (
            <Fragment>
              {urlVideo && (
                <Card sx={{ maxWidth: '500px', mt: 2, mb: 2 }}>
                  <CardMedia component="video" controls src={urlVideo} />
                </Card>
              )}
              {urlAudio && (
                <CardMedia
                  sx={{
                    maxWidth: '500px',
                    mt: 2,
                    mb: 2,
                    flexShrink: 0,
                  }}
                  component="audio"
                  controls
                  src={urlAudio}
                />
              )}
              <Box mt={2} mb={2} whiteSpace="pre-wrap" lineHeight="30px" sx={{ clear: 'both' }}>
                {htmlWithAnnotations && (
                  <FormGroup>
                    <FormControlLabel
                      checked={isAnnotatedTextDisplay}
                      control={<Switch onChange={handleToggleAnnotatedTextDisplay} />}
                      label={t('results.switChAnnotatedText')}
                    />
                  </FormGroup>
                )}
                {htmlWithAnnotations && isAnnotatedTextDisplay ? (
                  <div
                    className="html-with-tags"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: htmlWithAnnotations }}
                  />
                ) : (
                  text
                )}
              </Box>
            </Fragment>
          )}
        </Box>
      </Box>
    </Box>
  );
};

Document.propTypes = {
  disableTagsRefine: PropTypes.bool,
  displayTitle: PropTypes.bool,
  document: documentPropType.isRequired,
  handleGetDocumentComplete: PropTypes.func,
  handleToggleAnnotatedTextDisplay: PropTypes.func,
  isAnnotatedTextDisplay: PropTypes.bool,
  isCompleteDisplay: PropTypes.bool,
  shrinkDocumentDisplay: PropTypes.bool,
};

Document.defaultProps = {
  disableTagsRefine: false,
  displayTitle: true,
  handleGetDocumentComplete: null,
  handleToggleAnnotatedTextDisplay: null,
  isCompleteDisplay: false,
  isAnnotatedTextDisplay: false,
  shrinkDocumentDisplay: false,
};

export default Document;
