const today = new Date();
const threeMonthsAgo = new Date();
threeMonthsAgo.setMonth(today.getMonth() - 4);

export default {
  DEFAULT_SEARCH_PER_BASE: {
    22801201: {
      DATE_10000029_comp: '3',
      DATE_10000029_from: threeMonthsAgo,
    },
  },
};
